import RightActionArea from "../../../components/ActionAreaRight";

import { Icon } from "@iconify/react";
import { utils } from "../../../modules/_utils";
import Button from "../../../elements/Button";
import { PricingInput, TextInput } from "../components/DataEntry";
import InputWithDropdown from "../../../components/InputWithDropdown";
import { useEffect, useRef, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Input from "../../../components/Input";
import InputForVolumePricing from "../../../components/InputWithGraduatedAndVolume";
import DialogBox from "../../../components/Dialog";
import { PRICING_TYPES } from "../../../constants/constants";
import { rsort } from "semver";
import { Paragraph } from "../../../components/Typography/Paragraph";
import TextEditor from "../../../components/TextEditor";
import Tip from "../../../components/Tip";
import { StopCircleIcon } from "@heroicons/react/24/solid";
import Divider from "../../../components/Divider";
import SpreadsheetComponent from "../../../components/Spreadsheet/SpreadsheetComponent";
import { set } from "date-fns";
import QuestionToolTip from "../../../components/QuestionToolTip";
import Tabs from "../../component/Tabs";
import { get } from "immutable";
import { id } from "date-fns/locale";
const {
  scenarioConstants,
} = require("../../../constants/price_builder_constants");

const base = false;
export default function PricingResponseForm(props) {
  const {
    setServiceDrawerOpen,
    serviceDrawerOpen,
    autosaving,
    setDrawerOpen,
    drawerOpen,
    fullQuote,
    lineItemOptions,
    unitOptions,
    selectedTab,
    response,
    handleFieldChange,
    handleFieldChangeFromNewPricingResponse,
    handleAddNewPricingOption,
    handleRemovePricingOption,
    handleUpdateAfterDeleteLineItem,
    handlePricingChange,
    handleselect,
    handleSaveServiceClick,
    isLoading,
    isSaved,
    err,
    setErr,
    errMessage,
    setErrMessage,
    field,
    hideAddLineItems,
    serviceObject,
    scenarios,
    handleDeleteUnusedLineItems,
  } = props;
  const ADD_NEW_ICON = "ic:baseline-add";
  const ADD_NEW_TAB = {
    id: 99,
    name: "Add Option",
    href: "#",
    current: false,
    icon: ADD_NEW_ICON,
  };
  const [pricing, setPricing] = useState();
  const [selected, setSelected] = useState([]);
  const [tiers, setTiers] = useState([
    {
      min: 1,
      max: "∞",
      price: 0.01,
      currency: "USD",
      flat: {
        price: 0.0, //document, page, hour, GB
        total: 0.0,
      },
      unit_pricing: {
        price: 0.0, //document, page, hour, GB
        total: 0.0,
      },

      unit: "document", //document, page, hour, GB
    },
  ]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dataToConfirm, setDataToConfirm] = useState();
  const [dataMiningLineItems, setDataMiningLineItems] = useState([]);
  const [lineItemOptionList, setLineItemOptionList] = useState(
    props.lineItemOptionsList
  );
  const [estimatedPrice, setEstimatedPrice] = useState("");
  const [pricingOptions, setPricingOptions] = useState([]);
  const [pricingOptionTabs, setPricingOptionTabs] = useState([]);
  const [selectedPricingOptionTab, setSelectedPricingOptionTab] = useState(
    pricingOptionTabs[0]?.name || "Option 1"
  );

  const prevTabsLengthRef = useRef(pricingOptionTabs.length); // Ref to store previous length

  useEffect(() => {
    console.log("PricingResponseForm_ PRICING", selectedTab, pricing);
    console.log("PricingResponseForm_ RESPONSE", selectedTab, response);
    console.log("PricingResponseForm_ FULLQUOTE", selectedTab, fullQuote);
    console.log(
      "PricingResponseForm_ SERVICE_OBJECT",
      selectedTab,
      serviceObject
    );
    setPricingOptions(serviceObject?.options ? serviceObject.options : []);
  }, [pricing]);

  useEffect(() => {
    const prevLength = prevTabsLengthRef.current;
    const newLength = pricingOptionTabs.length;

    if (newLength > prevLength) {
      // A tab was added
      setSelectedPricingOptionTab(pricingOptionTabs[newLength - 2].name);
    } else if (newLength < prevLength) {
      // A tab was deleted, set to first tab
      // setSelectedPricingOptionTab(pricingOptionTabs[0]?.name || "");
      //a tab was deleted, set to the next to last tab unless there is only one tab, then set to the first tab
      setSelectedPricingOptionTab(
        pricingOptionTabs[newLength - 2]?.name || pricingOptionTabs[0]?.name
      );
    }

    // Update the ref with the new length
    prevTabsLengthRef.current = newLength;
  }, [pricingOptionTabs]);

  // useEffect(() => {
  //   // Initialize newTabs array
  //   let newTabs = [];
  //   getHighestPricingOptionId();
  //   const optionLength = serviceObject?.options
  //     ? serviceObject.options.length
  //     : 0;
  //   // Check if pricingOptions exist and have length greater than 0
  //   if (optionLength > 0) {
  //     handleAddNewPricingOptionTab();
  //     // Map the pricing options to the tabs
  //     newTabs = serviceObject.options.map((option, index) => {
  //       return {
  //         name: option.name,
  //         href: "#",
  //         current: selectedPricingOptionTab === option.name,
  //       };
  //     });
  //     console.log("USEEFFECT SHOULD HAVE STOPPED HERE");
  //   } else {
  //     console.log("USEEFFECT DID NOT STOP", pricingOptions.length);
  //     handleCreateDefaultPricingOption();

  //     // If no pricing options exist, create a default option
  //   }
  //   // setSelectedPricingOptionTab("Option 1");

  //   // Add an "Add Option" tab

  //   // Set the pricing option tabs
  //   setPricingOptionTabs(newTabs);
  // }, []);

  useEffect(() => {
    // Initialize newTabs array
    let newTabs = [];
    const optionLength = serviceObject?.options
      ? serviceObject.options.length
      : 0;

    // Check if pricingOptions exist and have length greater than 0
    if (optionLength > 0) {
      // Map the pricing options to the tabs
      newTabs = serviceObject.options.map((option) => {
        return {
          name: option.name || `Option ${option.id}`,
          href: "#",
          current: selectedPricingOptionTab === `Option ${option.id}`,
        };
      });
    } else {
      // If no pricing options exist, create a default option
      const defaultOption = handleCreateDefaultPricingOption();
      newTabs.push({
        name: defaultOption.name || `Option ${defaultOption.id}`,
        href: "#",
        current: true,
      });
    }

    // Add an "Add Option" tab
    newTabs.push(ADD_NEW_TAB);

    // Set the pricing option tabs
    setPricingOptionTabs(newTabs);
    setSelectedPricingOptionTab(newTabs[0].name);
  }, []);
  const getHighestPricingOptionId = () => {
    if (pricingOptions.length === 0) {
      return 1;
    } else {
      const highestId = Math.max(...pricingOptions.map((option) => option.id));
      return highestId;
    }
  };

  const handleCreateDefaultPricingOption = () => {
    const newPricingOption = {
      id: 1,
      name: "Option 1",
      description: "",
      pricing: {},
    };
    console.log(
      "Initialize handleCreateDefaultPricingOption",
      newPricingOption
    );
    setPricingOptions([newPricingOption]);
    handleAddNewPricingOption(selectedTab, newPricingOption);
    return newPricingOption;
  };

  const createNewPricingOption = () => {
    const id = getHighestPricingOptionId() + 1;
    console.log(
      "handleAddNewPricingOptionTab 1 > Initialize createNewPricingOption",
      id
    );
    const newItem = {
      id: id,
      name: `Option ${id}`,
      description: "",
      pricing: {},
    };

    console.log(
      "handleAddNewPricingOptionTab 2 > createNewPricingOption NewItem",
      newItem
    );
    setPricingOptions((prevOptions) => [...prevOptions, newItem]);
    handleAddNewPricingOption(selectedTab, newItem);
    return newItem;
  };

  const createNewTab = (newPricingOption) => {
    console.log("handleAddNewPricingOptionTab 6", newPricingOption);
    const newTabObject = {
      id: newPricingOption.id,
      name: `Option ${newPricingOption.id}`,
      href: "#",
      current: true,
    };

    setPricingOptionTabs((prevTabs) => [
      ...prevTabs.filter((tab) => tab.name !== "Add Option"),
      newTabObject,
      ADD_NEW_TAB,
    ]);

    // setPricingOptions((prevOptions) => [...prevOptions, newPricingOption]);
    setSelectedPricingOptionTab(newTabObject.name);
  };

  const handleAddNewPricingOptionTab = () => {
    console.log("handleAddNewPricingOptionTab 0");
    const newPricingOption = createNewPricingOption();
    console.log("handleAddNewPricingOptionTab 5", newPricingOption);
    createNewTab(newPricingOption);
    console.log("handleAddNewPricingOptionTab 2", newPricingOption);
  };

  // const handleAddNewPricingOptionTab = () => {
  //   // add a new option

  //   let tabs = [...pricingOptionTabs];
  //   let newPricingOptions = [...pricingOptions];

  //   let newPricingOption = {};
  //   if (pricingOptions.length === 0) {
  //     newPricingOption = handleCreateDefaultPricingOption();
  //   } else {
  //     newPricingOption = {
  //       id: getHighestPricingOptionId() + 1,
  //       name: "Option " + (newPricingOptions.length + 1),
  //       pricing: {},
  //     };
  //   }

  //   let newTab = "Option " + (newPricingOptions.length + 1);
  //   let newTabObject = {
  //     name: "Option " + (newPricingOptions.length + 1),
  //     href: "#",
  //     current: true,
  //   };
  //   tabs.push(newTabObject);
  //   setPricingOptionTabs(tabs);

  //   newPricingOptions.push(newPricingOption);
  //   setPricingOptions(newPricingOptions);
  //   setSelectedPricingOptionTab(newTab);
  //   //this needs to update the pricingOptions in the respons
  //   handleAddNewPricingOption(selectedTab, newPricingOption);
  //   return newTab;
  // };

  const removeTabById = (tabId) => {
    let tabs = [...pricingOptionTabs];
    let newTabs = tabs.filter((tab) => tab.id !== tabId);
    setPricingOptionTabs(newTabs);
  };

  const removeTabByName = (tabName) => {
    let tabs = [...pricingOptionTabs];
    let newTabs = tabs.filter((tab) => tab.name !== tabName);
    setPricingOptionTabs(newTabs);
  };

  const removePricingOption = (tabName) => {
    let newPricingOptions = [...pricingOptions];
    let index = newPricingOptions.findIndex((x) => x.name === tabName);
    newPricingOptions.splice(index, 1);
    setPricingOptions(newPricingOptions);
    handleRemovePricingOption(selectedTab, newPricingOptions);
  };

  useEffect(() => {
    if (response && response[selectedTab] && response[selectedTab]["price"]) {
      setEstimatedPrice(response[selectedTab]["price"]);
    }
  }, [response, selectedTab]);

  useEffect(() => {
    setLineItemOptionList(props.lineItemOptionsList);
  }, [selectedTab]);

  useEffect(() => {
    // if Object.keys(pricing) are in lineItemOptions, remove them from lineItemOptions and set the new lineItemOptions
    let newPricingOptions = Array.isArray(lineItemOptions)
      ? [...lineItemOptions]
      : [];
    if (pricing && pricing !== undefined && pricing !== null) {
      Object.keys(pricing).map((key, index) => {
        let idx = newPricingOptions.findIndex(
          (x) => x.name === key || x.value === key
        );
        if (idx > -1) {
          newPricingOptions.splice(idx, 1);
        }
      });
    } else {
      return;
    }

    setDataMiningLineItems(newPricingOptions);
  }, [selectedTab, lineItemOptions]);

  useEffect(() => {
    // console.log(
    //   "USEEFFECT:PricingResponseForm",
    //   selectedTab,
    //   field,
    //   response[selectedTab][field]
    // );
    response &&
    response !== undefined &&
    response !== null &&
    response[selectedTab] &&
    response[selectedTab][field] &&
    response[selectedTab][field] !== undefined &&
    field == "pricing"
      ? setPricing(response[selectedTab][field])
      : setPricing({});
  }, [selectedTab, field, response]);

  useEffect(() => {
    console.log("USEEFFECT:PricingResponseForm", response && response);
  }, [response]);

  const handleSetEstimatedPrice = (e, value, index) => {
    console.log("handleSetEstimatedPrice", value);
    if (isNaN(value)) {
      console.log("handleSetEstimatedPrice isNaN failed");
      setErr(true);
      setErrMessage("You may only enter numbers for the estimated price.");
      setTimeout(() => {
        setErr(false);
        setErrMessage("");
      }, 5000);
    } else {
      console.log("handleSetEstimatedPrice passed checks");
      setErr(false);
      setErrMessage("");
      setEstimatedPrice(value);
      handleFieldChange(e, selectedTab, "handleSetEstimatedPrice");
      if (props.version && props.version == "v2") {
        console.log("handleFieldChangeFromNewPricingResponse");
        handleFieldChangeFromNewPricingResponse(
          e,
          selectedTab,
          "handleSetEstimatedPrice",
          index
        );
      } else {
        console.log(
          "V2 pricing do not call this function, handleFieldChangeFromNewPricingResponse"
        );
      }
    }
  };
  const updatePricingOptionsWithPricing = (pricingOptions, pricing) => {
    let newPricingOptions = [...pricingOptions];
  };
  const getScenarios = (options) => {
    console.log("getScenarios", scenarios[selectedTab]);

    //get a list of scenarios from the all options.description
    let arrScenarioNames = [];
    options.map((option) => {
      if (option.description) {
        arrScenarioNames.push(option.description);
      }
    });
    console.log("getScenarios", arrScenarioNames);

    let allScenarios = scenarios[selectedTab];
    //filter out scenarios that are in arrScenarioNames
    let availableScenarios = allScenarios.filter(
      (scenario) => !arrScenarioNames.includes(scenario.name)
    );
    console.log("getScenarios available scenarios", availableScenarios);
    return availableScenarios;
  };

  const handlePriceChangeFromSheetWithPricingOptions = (
    value,
    service,
    item,
    line_item_type,
    index
  ) => {
    // value is the value of the input
    // service is the name of the service
    // item is the name of the pricing item
    // line_item_type is the type of line item, standard, graduated, volume
    item = item ? item : "base";

    // Ensure pricingOptions and pricingOptions[index] are defined
    if (!pricingOptions || !pricingOptions[index]) {
      console.error("Pricing options or the specified index is undefined");
      return;
    }

    let priceObj = pricingOptions[index].pricing;
    console.log("handlePriceChangeFromSheet pricingObj", priceObj);

    let newPricingOptions = [...pricingOptions];
    console.log(
      "handlePriceChangeFromSheet newPricingOptions",
      newPricingOptions
    );

    // Ensure newPricingOptions[index].pricing is defined
    if (!newPricingOptions[index].pricing) {
      newPricingOptions[index].pricing = {};
    }

    // Pricing item does not exist, create it
    if (!newPricingOptions[index].pricing[item]) {
      console.log("Pricing item does not exist, create it");
      newPricingOptions[index].pricing[item] = {
        type: line_item_type,
        price: value.price,
        qty: value.qty,
        unitPrice: value.unitPrice,
        currency: "USD",
        measure: "per_unit", // flat, per_unit
        unit: value.unit, // document, page, hour, GB
        tiers: value.tiers,
        comments: value.comments,
        included: value.included || "No",
      };
      console.log(
        "handlePriceChangeFromSheet",
        value,
        service,
        item,
        line_item_type
      );
    } else {
      // Pricing item exists, update it without knowing all the incoming values
      console.log(
        "Pricing item exists, update it without knowing all the incoming values"
      );
      Object.keys(value).map((key) => {
        newPricingOptions[index].pricing[item][key] = value[key];
      });
    }
    console.log(
      "handlePriceChangeFromSheet newPricingOptions",
      newPricingOptions
    );

    let e = {
      target: {
        value: newPricingOptions[index].pricing,
        name: "pricing",
      },
    };
    console.log("Calling handleFieldChangeFromNewPricingResponse with e", e);

    // Update the newPricingOptions[index].price as well
    setPricingOptions(newPricingOptions);
    // handleFieldChange(e, selectedTab);
    handleFieldChangeFromNewPricingResponse(
      e,
      selectedTab,
      "handlePriceChange",
      index
    );
  };
  const handlePriceChangeFromSheet = (
    value,
    service,
    item,
    line_item_type,
    index
  ) => {
    // value is the value of the input
    // service is the name of the service
    // item is the name of the pricing item
    // line_item_type is the type of line item, standard, graduated, volume

    item = item ? item : "base";
    //get pricing from pricingOptions based on index
    let priceObj = pricingOptions[index].pricing;
    console.log("handlePriceChangeFromSheet pricingObj", priceObj);
    let newPricing = { ...pricing };
    console.log("handlePriceChangeFromSheet newPricing", newPricing);

    //pricing item does not exist, create it
    if (!newPricing[item]) {
      newPricing[item] = {
        type: line_item_type,
        price: value.price,
        qty: value.qty,
        unitPrice: value.unitPrice,

        currency: "USD",
        measure: "per_unit", //flat, per_unit
        unit: value.unit, //document, page, hour, GB
        tiers: value.tiers,
      };
      console.log(
        "handlePriceChangeFromSheet",
        value,
        service,
        item,
        line_item_type
      );
    } else {
      //pricing item exists, update it without knowing all the incoming values
      Object.keys(value).map((key, index) => {
        newPricing[item][key] = value[key];
      });
    }
    let e = {
      target: {
        value: newPricing,
        name: "pricing",
      },
    };
    setPricing(newPricing);
    handleFieldChange(e, selectedTab);
  };
  const handlePriceChange = (value, service, item, line_item_type) => {
    // value is the value of the input
    // service is the name of the service
    // item is the name of the pricing item
    // line_item_type is the type of line item, standard, graduated, volume

    item = item ? item : "base";

    let newPricing = { ...pricing };

    //pricing item does not exist, create it
    if (!newPricing[item]) {
      newPricing[item] = {
        type: line_item_type,
        price: value.price,
        currency: "USD",
        measure: "per_unit", //flat, per_unit
        unit: value.unit, //document, page, hour, GB
        tiers: value.tiers,
      };
      console.log("handlePriceChange", value, service, item, line_item_type);
    } else {
      //pricing item exists, update it without knowing all the incoming values
      Object.keys(value).map((key, index) => {
        newPricing[item][key] = value[key];
      });
    }
    let e = {
      target: {
        value: newPricing,
        name: "pricing",
      },
    };
    setPricing(newPricing);
    handleFieldChange(e, selectedTab);
  };
  const addLineItem = (e, service, type) => {
    let newPricing = { ...pricing };
    let pricingSize = Object.keys(newPricing).length;
    let tmpKey = "tmp" + pricingSize;
    newPricing[tmpKey] = {
      type: e,
    };
    console.log("addLineItem", e, service, field, type);
    setPricing(newPricing);
  };
  const handleSetLineItem = (e, service, field, type, item) => {
    //rename tmp to the name of the line item
    let newPricing = { ...pricing };
    let keys = Object.keys(newPricing);
    console.log("Compare", e, keys, " for duplicates");
    //check to see if the new line (e) already exists in the pricing object
    let exists = keys.includes(e);
    //if it exists, set an error and alert
    if (exists) {
      console.log("exists", exists);
      // window.alert("This line item already exists");
      // err(true);
      // errMessage("This line item already exists");
    } else {
      console.log("does not exist", exists);
      // window.alert("This line item does not exist", e);
      let tmp = newPricing[item ? item : "tmp"];
      delete newPricing[item];
      newPricing[e] = tmp;
      console.log("handleSetLineItem", e, service, field, type);
      setPricing(newPricing);
      //remove e from the lineitem options
      let newLineItems = [...dataMiningLineItems];
      let index = newLineItems.findIndex((x) => x.name === e);
      newLineItems.splice(index, 1);
      setDataMiningLineItems(newLineItems);
    }
  };

  const deleteLineItem = (e, item) => {
    e.preventDefault();
    console.log("deleteLineItem", e, item);
    // window.alert("Delete line item", e, item);
    //show confirmation box first
    //if confirmed, delete line item
    //if not confirmed, do nothing
    setConfirmDialogOpen(true);
    setDataToConfirm(item);
  };
  const handleConfirmDelete = (e) => {
    console.log(
      "Deleting item, deleteLineItem -> handleConfirmDelete",
      dataToConfirm
    );
    let newPricing = { ...pricing };
    delete newPricing[dataToConfirm];
    console.log(
      "deleteLineItem -> handleConfirmDelete -> newPricing",

      dataToConfirm,
      newPricing
    );
    setPricing(newPricing);
    setDataToConfirm();
    setConfirmDialogOpen(false);
    //update response state
    handleUpdateAfterDeleteLineItem(dataToConfirm, newPricing);
    //update line item options to include the deleted item
    let newLineItems = [...dataMiningLineItems];
    newLineItems.push({ name: dataToConfirm, value: dataToConfirm });
    setDataMiningLineItems(newLineItems);
  };

  return (
    <div className="h-full overflow-hidden ">
      <div className="sticky top-0 bg-white space-y-2 mb-0 ">
        <div className="w-full flex items-center justify-between min-h-8 max-h-8">
          <div className="flex items-center ">
            <h2 className="text-lg font-semibold leading-6 font-medium text-gray-900">
              Vendor Response
            </h2>
          </div>
          <div className="flex items-center space-x-6">
            {autosaving ? (
              <span className="inline-flex items-center text-kelly-500 font-medium mr-2">
                <Icon
                  icon="ic:baseline-check-circle"
                  className="mr-1 w-5 h-5 text-green-500"
                />
                Autosaving...
              </span>
            ) : (
              <Button
                size={"sm"}
                loader={isLoading(selectedTab) && !autosaving}
                // loader={false}
                disabled={
                  fullQuote.status == "submitted" || err || isSaved(selectedTab)
                }
                handleClick={(e) => {
                  handleSaveServiceClick(e, selectedTab);
                }}
                type="submit"
                color="secondary"
                className=""
                // disabled={err || saved}
                // className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                <span className="flex items-center">
                  <Icon icon="mdi:content-save" className="flex mr-2" />
                  <span className="hidden lg:flex">
                    Save{" "}
                    {utils.cleanUpKeys(
                      selectedTab && selectedTab !== undefined
                        ? selectedTab
                        : "Service"
                    )}
                  </span>
                </span>
              </Button>
            )}

            <Button
              color={"emerald"}
              size={"sm"}
              handleClick={() => setServiceDrawerOpen(true)}
            >
              <Icon icon="gravity-ui:square-list-ul" className="mr-2" />
              <span className="hidden lg:flex">
                Show {utils.cleanUpKeys(selectedTab)} Request
              </span>
            </Button>
            <Button
              size={"sm"}
              color={"outline"}
              handleClick={(e) => {
                e.preventDefault();
                console.log("Pricing help clicked");
                setDrawerOpen(true);
              }}
              className="mr-2"
              variant="secondary"
              loader={isLoading("pricing-help")}
            >
              <Icon icon="mdi:help-circle-outline" className="mr-2" />{" "}
              <span className="hidden lg:flex">Pricing help</span>
            </Button>
          </div>
        </div>

        <div className="col-span-full mt-0">
          {/* <Paragraph md>Enter your response for this service below.</Paragraph> */}
        </div>
      </div>
      <div
        className={`h-full pb-48 col-span-12  px-3 overflow-y-auto overflow-x-hidden 
    scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
    scrollbar-track-rounded-full scrollbar-thumb-rounded-full`}
      >
        {/* <Tip
          icon={
            <Icon
              icon="mingcute:alert-fill"
              className=" my-auto inline-flex w-5 h-5 mr-1 text-red-500"
            />
          }
          color="red"
          colSpan={"full"}
        >
          <span className="">
            {" "}
            <span className="font-semibold">
              {" "}
              Please read the bid request and its assumptions carefully.{" "}
            </span>
            The requestor requires your response and all comments to be{" "}
            <span className="font-semibold">
              based on the bid request and its assumptions
            </span>
            . Additionally,{" "}
            <span className="font-semibold">
              you must enter line items for all services
            </span>{" "}
            you bill for. You may use the Additional Comments field for line
            items that are not listed. When entering the Estimated Cost,{" "}
            <span className="font-semibold">
              enter the Estimated Total Cost of the bid
            </span>
            . For example, do not enter $1 if your price is $1 per document or
            letter or redemption. If you have any questions, please ask the
            requestor in the comments.{" "}
            <span className="font-bold">
              Failure to follow the bid request instructions may result in your
              bid being disqualified or sent back for revision.
            </span>
          </span>
        </Tip> */}
        <div className="w-full col-span-full pb-6 space-y-2">
          <Tip color="amber" showClose>
            Unsolicited information in bids can confuse and frustrate users,
            leading to fewer bid requests being created. In some cases, this has
            even been a deciding factor in selecting a winner. It is essential
            to thoroughly read the bid request and ensure your quote only
            includes what is being asked for. If you wish to provide additional
            information, please upload it using the Attachments tab.
          </Tip>
          <div className="">
            <span className="font-bold">Instructions: </span> Use the
            spreadsheet below to enter one or more pricing options
            <QuestionToolTip
              field="Multiple Pricing Options"
              size="md"
              text="Sometimes requestors want pricing for more than one scenario.  Separating the pricing into multiple options allows you to show the requestor the different pricing without any confusion. "
            />
            . To create a new option, click "New Option". Then you can select a
            scenario and enter your pricing.{" "}
            <span className="font-bold">
              You may only enter pricing for the line items provided.
            </span>
            <QuestionToolTip
              field="Line Items"
              size="md"
              text="For consistency, you may only enter pricing for the line items provided in each scenario."
            />{" "}
            If you would like to provide a menu of other offerings and prices,
            you may do so in the Additional Comments.
            <QuestionToolTip
              field="Additional Pricing"
              size="md"
              text="If you want to provide the requestor with pricing for additional services, you may do so in the additional comments.  For example, if the request is for 1 language and you want to show the requestor your cost for additional languages, this should be entered in the Additional Comments as Optional Services."
            />{" "}
            If you have any questions, please ask the requestor in the comments.
            Keep in mind that{" "}
            <span className="font-bold">
              all other vendors can see your comments.
            </span>
          </div>
        </div>

        {props.version && props.version == "v2" && (
          <div className="w-full col-span-full py-1 space-y-0">
            <Tabs
              tabs={pricingOptionTabs}
              setSelectedTab={(tab) => {
                console.log("setSelectedTab TAB clicked", tab);
                if (tab === "Add Option") {
                  handleAddNewPricingOptionTab();
                } else {
                  setSelectedPricingOptionTab(tab);
                }
              }}
              selectedTab={selectedPricingOptionTab}
            />
          </div>
        )}

        {props.version &&
          props.version == "v2" &&
          pricingOptions &&
          pricingOptions.length > 0 &&
          pricingOptions.map((option, index) => {
            console.log("MAPPING PRICING OPTIONS", option);
            let priceObj = option.pricing ? option.pricing : {};
            let name = option.name;
            let id = option.id;
            console.log("MAPPING PRICING OPTIONS", id, name, priceObj);
            if (name === selectedPricingOptionTab) {
              // console.log("MAPPING PRICING OPTIONS", name, priceObj);
              return (
                <div className="space-y-8">
                  <SpreadsheetComponent
                    id={id}
                    name={name}
                    index={index}
                    scenarios={scenarios[selectedTab] || []}
                    scenarioOptions={getScenarios(pricingOptions)}
                    // scenarios={getScenarios(pricingOptions)}
                    initialDescription={option.description}
                    servicePrice={option.price || 0}
                    initialLineItems={lineItemOptionList}
                    pricingItems={priceObj}
                    service={selectedTab}
                    option
                    handleSetEstimatedPrice={(e) => {
                      handleSetEstimatedPrice(e, e.target.value, index);
                    }}
                    handlePriceChange={(v, service, item) => {
                      console.log("handlePriceChange", v, service, item);

                      handlePriceChangeFromSheetWithPricingOptions(
                        v,
                        service,
                        item,
                        "Standard",
                        index
                      );
                    }}
                    handleDeleteOption={(e) => {
                      console.log("Delete Tab", e, name, id);
                      removeTabByName(name);
                      // removeTabById(id);
                      removePricingOption(name);
                    }}
                    handleDeleteUnusedLineItems={(lineItems) =>
                      handleDeleteUnusedLineItems(selectedTab, index, lineItems)
                    }
                    handleChangeDescription={(e) => {
                      console.log(
                        "handleChangeDescription",
                        e,
                        selectedTab,
                        "description",
                        index
                      );
                      handleFieldChangeFromNewPricingResponse(
                        e,
                        selectedTab,
                        "handleChangeDescription",
                        index
                      );
                    }}
                  />
                  <div className="w-full col-span-full ">
                    <label
                      htmlFor="additional_comments"
                      className="text-sm font-medium pb-6"
                    >
                      Additional Information for{" "}
                      {utils.cleanUpKeys(
                        selectedTab && selectedTab !== undefined
                          ? selectedTab
                          : "Service"
                      )}{" "}
                      {name}
                    </label>
                    <TextEditor
                      field="additional_comments_object"
                      selectedTab={selectedTab}
                      response={response}
                      handleFieldChange={(e, rawContent) => {
                        handleFieldChangeFromNewPricingResponse(
                          {
                            target: {
                              name: "additional_comments_object",
                              value: rawContent,
                            },
                          },
                          selectedTab,
                          "handleChangeDescription",
                          index
                        );
                      }}
                      // handleFieldChange={(e, rawContent) => {
                      //   handleFieldChange(
                      //     {
                      //       target: {
                      //         name: "additional_comments_object",
                      //         value: rawContent,
                      //       },
                      //     },
                      //     selectedTab
                      //   );
                      // }}
                      handleSaveToAdditionalComments={(e, content) => {
                        handleFieldChangeFromNewPricingResponse(
                          {
                            target: {
                              name: "additional_comments",
                              value: content,
                            },
                          },
                          selectedTab,
                          "handleChangeDescription",
                          index
                        );
                      }}
                      handleSaveServiceClick={handleSaveServiceClick}
                      isLoading={isLoading}
                      isSaved={isSaved}
                      err={err}
                      errMessage={errMessage}
                      value={
                        option && option["additional_comments_object"]
                          ? option["additional_comments_object"]
                          : ""
                      }
                    />
                    {/* <textarea
              //multi line
  
              id="additional_comments"
              name="additional_comments"
              type="text"
              autoComplete="additional_comments"
              rows={8}
              disabled={fullQuote && fullQuote.status == "submitted"}
              value={
                response &&
                response[selectedTab] &&
                response[selectedTab]["additional_comments"]
                  ? response[selectedTab]["additional_comments"]
                  : ""
              }
              // value={response[selectedTab]["additional_comments"] || ""} //{selectedTab}
              className="max-w-full min-w-full w-full  px-5 py-3 placeholder-gray-500 focus:ring-brand-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
              placeholder="Enter your quote for this service here"
              onChange={(e) => {
                handleFieldChange(e, selectedTab);
              }}
            /> */}
                  </div>
                </div>
              );
            } else {
              console.log(
                `Skipping ${name} becaust it does not match ${selectedPricingOptionTab}`
              );
              return <></>;
            }
          })}
        {(!props.version || props.version !== "v2") && (
          <div
            id={"responsePanel"}
            className="bg flex flex-col col-span-full items-center space-y-4  pb-12 m-auto "
          >
            <div className=" w-full">
              <div
                className="flex bg-brand-50 border-2 border-dashed border-brand-200 px-6 pb-2 rounded-sm"
                // className={"-mt-12"}
                noIcon
              >
                <div className="relative w-full col-span-full py-1">
                  <label
                    htmlFor="service_price"
                    className="text-sm font-medium  mb-2"
                  >
                    <span className="py-4">
                      Estimated Cost for{" "}
                      {utils.cleanUpKeys(
                        selectedTab && selectedTab !== undefined
                          ? selectedTab
                          : "Service"
                      )}
                    </span>
                  </label>
                  <div className="pb-3  items-end text-xl pointer-events-none absolute inset-y-0 left-0 flex pl-3">
                    $
                  </div>
                  <input
                    id="price"
                    name="price"
                    type="text"
                    autoComplete="price"
                    disabled={fullQuote && fullQuote.status == "submitted"}
                    required
                    placeholder="Estimated total cost for this service."
                    className="mt-2 block w-full min-w-full max-w-full pl-10 pr-40 py-3 placeholder-gray-500  focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                    //   placeholder={`Enter your ${utils.cleanUpKeys(field)} pricing.`}
                    value={estimatedPrice}
                    onChange={(e) => {
                      handleSetEstimatedPrice(e, e.target.value);
                    }}
                  />
                </div>
                {err && (
                  <label
                    className="w-full mt-1 space-y-0 text-sm text-red-600"
                    id="email-error"
                  >
                    {errMessage}
                  </label>
                )}
              </div>
            </div>
            {base && (
              <InputWithDropdown
                version={3}
                fullQuote={fullQuote}
                selectedTab={selectedTab}
                label={"Base Pricing"}
                lineItem={"base"}
                unitOptions={unitOptions}
                service={selectedTab}
                response={response}
                pricing={pricing}
                field={field}
                handlePriceChange={(v, service) =>
                  handlePriceChange(v, service, "base", "Standard")
                }
                indicator="$"
                value={pricing && pricing["base"] && pricing["base"]}
                isLoading={isLoading}
                isSaved={isSaved}
                handleSaveServiceClick={handleSaveServiceClick}
                err={err}
                errMessage={errMessage}
                //not used?
                handleFieldChange={handleFieldChange}
                onFieldChange={handleFieldChange}
                // handleSelect={handleSelect}
              />
            )}
            {pricing &&
              !hideAddLineItems &&
              Object.keys(pricing).map((item, index) => {
                console.log("MAPPING PRICING", item, pricing[item].type);
                if (item !== "base" && pricing[item].type == "Standard") {
                  return (
                    <InputWithDropdown
                      version={2}
                      fullQuote={fullQuote}
                      selectedTab={selectedTab}
                      service={selectedTab}
                      response={response}
                      // label={utils.cleanUpKeys(item)}
                      pricing={pricing}
                      field={field}
                      lineItem={item}
                      lineItemOptions={lineItemOptionList}
                      unitOptions={unitOptions}
                      deleteLineItem={(e) => deleteLineItem(e, item)}
                      handlePriceChange={(v, service, item) =>
                        handlePriceChange(v, service, item, "Standard")
                      }
                      handleSelectLineItem={(v, service, field, type) =>
                        handleSetLineItem(v, service, field, type, item)
                      }
                      indicator="$"
                      value={pricing && pricing[item] && pricing[item]}
                      isLoading={isLoading}
                      isSaved={isSaved}
                      handleSaveServiceClick={handleSaveServiceClick}
                      err={err}
                      errMessage={errMessage}
                      //not used?
                      handleFieldChange={handleFieldChange}
                      onFieldChange={handleFieldChange}
                      // handleSelect={handleSelect}
                    />
                  );
                } else if (
                  pricing[item].type == "Graduated" ||
                  pricing[item].type == "Volume"
                )
                  return (
                    <InputForVolumePricing
                      version={2}
                      fullQuote={fullQuote}
                      selectedTab={selectedTab}
                      service={selectedTab}
                      response={response}
                      label={utils.cleanUpKeys(item)}
                      pricing={pricing}
                      field={field}
                      item={item}
                      lineItem={item}
                      deleteLineItem={(e) => deleteLineItem(e, item)}
                      lineItemOptions={dataMiningLineItems}
                      unitOptions={() => {
                        //remove fixed price from the unit options
                        let newUnitOptions = [...unitOptions];
                        let index = newUnitOptions.findIndex(
                          (x) => x.name === "fixed"
                        );
                        newUnitOptions.splice(index, 1);
                        return newUnitOptions;
                      }}
                      handlePriceChange={(v, service) =>
                        handlePriceChange(v, service, item, pricing[item].type)
                      }
                      handleSelectLineItem={(v, service, field, type) =>
                        handleSetLineItem(v, service, field, type, item)
                      }
                      indicator="$"
                      value={pricing && pricing[item] && pricing[item]}
                      isLoading={isLoading}
                      isSaved={isSaved}
                      handleSaveServiceClick={handleSaveServiceClick}
                      err={err}
                      errMessage={errMessage}
                      //not used?
                      handleFieldChange={handleFieldChange}
                      onFieldChange={handleFieldChange}
                      // handleSelect={handleSelect}
                    />
                  );
              })}
            {err && (
              <label
                className="w-full mt-1 space-y-0 text-sm text-red-600"
                id="email-error"
              >
                {errMessage}
              </label>
            )}{" "}
            <div className="w-full relative"></div>
            {!hideAddLineItems && (
              <div className="w-full col-span-full ">
                <ComboboxSelector
                  name={"unit"}
                  options={PRICING_TYPES}
                  textbutton={true}
                  buttonType={"line_item_divider"}
                  fieldType={"single"}
                  open={true}
                  handleSelect={(v) => {
                    addLineItem(v, selectedTab, "line_item");
                  }}
                  handleDeselect={null}
                  handleClearAllSelections={(value) => null} //{handleClearAllSelections}
                  value={() => {
                    "project_management";
                  }}
                />
              </div>
            )}
            <div className="w-full col-span-full ">
              <label
                htmlFor="additional_comments"
                className="text-sm font-medium pb-6"
              >
                Additional Information for{" "}
                {utils.cleanUpKeys(
                  selectedTab && selectedTab !== undefined
                    ? selectedTab
                    : "Service"
                )}
              </label>
              <TextEditor
                field="additional_comments_object"
                selectedTab={selectedTab}
                response={response}
                handleFieldChange={(e, rawContent) => {
                  handleFieldChange(
                    {
                      target: {
                        name: "additional_comments_object",
                        value: rawContent,
                      },
                    },
                    selectedTab
                  );
                }}
                handleSaveToAdditionalComments={(e, content) => {
                  handleFieldChange(
                    {
                      target: {
                        name: "additional_comments",
                        value: content,
                      },
                    },
                    selectedTab
                  );
                }}
                handleSaveServiceClick={handleSaveServiceClick}
                isLoading={isLoading}
                isSaved={isSaved}
                err={err}
                errMessage={errMessage}
                value={
                  response &&
                  response[selectedTab] &&
                  response[selectedTab]["additional_comments_object"]
                    ? response[selectedTab]["additional_comments_object"]
                    : ""
                }
              />
              {/* <textarea
              //multi line
  
              id="additional_comments"
              name="additional_comments"
              type="text"
              autoComplete="additional_comments"
              rows={8}
              disabled={fullQuote && fullQuote.status == "submitted"}
              value={
                response &&
                response[selectedTab] &&
                response[selectedTab]["additional_comments"]
                  ? response[selectedTab]["additional_comments"]
                  : ""
              }
              // value={response[selectedTab]["additional_comments"] || ""} //{selectedTab}
              className="max-w-full min-w-full w-full  px-5 py-3 placeholder-gray-500 focus:ring-brand-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
              placeholder="Enter your quote for this service here"
              onChange={(e) => {
                handleFieldChange(e, selectedTab);
              }}
            /> */}
            </div>
            <div className="mt-6 w-2/3 rounded-md  sm:mt-3 sm:ml-3 sm:flex-shrink-0">
              {/* <Button
                loader={isLoading(selectedTab)}
                disabled={
                  fullQuote.status == "submitted" || err || isSaved(selectedTab)
                }
                handleClick={(e) => {
                  handleSaveServiceClick(e, selectedTab);
                }}
                type="submit"
                color="secondary"
                fullWidth
                className="w-full"
                // disabled={err || saved}
                // className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                {autosaving ? (
                  <span className="inline-flex items-center text-kelly-500 font-medium mr-2">
                    <Icon
                      icon="ic:baseline-check-circle"
                      className="mr-1 w-5 h-5 text-green-500"
                    />
                    Autosaving...
                  </span>
                ) : (
                  <span>
                    <Icon icon="mdi:content-save" className="mr-2" />
  
                    {utils.cleanUpKeys(
                      selectedTab && selectedTab !== undefined
                        ? selectedTab
                        : "Service"
                    )}
                  </span>
                )}
              </Button> */}
            </div>
          </div>
        )}

        {confirmDialogOpen && (
          <DialogBox
            loading={false}
            open={confirmDialogOpen}
            // success={success}
            title="Delete Line Item"
            message={"Are you sure you want to delete this line item?"}
            OKbuttonText={"Delete"}
            CancelButton={"Cancel"}
            setOpen={setConfirmDialogOpen}
            data={dataToConfirm}
            handleConfirmClick={(data) => handleConfirmDelete(data)}
            handleCancelClick={() => setConfirmDialogOpen(false)}
          ></DialogBox>
        )}
      </div>
    </div>
  );
}
