import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";
import { ErrorLogging } from "./_error_logging";

const { postRequest, getRequest } = require("./ApiRequest");

export const Responses = {
  async getResponseByInviteId(data) {
    console.log("Call getResponseByInviteId and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token?.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", data);
    let response = await postRequest(
      "responses/get-response-by-invite-id",
      data
    );
    if (response) {
      console.log(
        "getResponseByInviteId --> /responses/get-response-by-invite-id response",
        response.data.data
      );
      return response.data.data;
    } else {
      return response;
    }
  },
  async create(data) {
    console.log(
      "Call API and pass data for Responses.create",
      JSON.parse(JSON.stringify(data))
    );
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    // data.tenantId = profile.tenantId;
    // data.companyId = profile.companyId;
    // console.log("API body", JSON.stringify(data));
    let response = await postRequest("responses/create", data);
    if (response && response.data && response.data.data) {
      console.log("responses/create", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async SetEditStatus(data) {
    console.log(
      "Call API and pass data setEditStatus",
      JSON.parse(JSON.stringify(data))
    );
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.status = "draft";
    // data.tenantId = profile.tenantId;
    // data.companyId = profile.companyId;
    console.log("API body set edit status", JSON.stringify(data));
    let response = await postRequest("responses/set-edit", data);
    if (response && response.data && response.data.data) {
      console.log("responses/set-edit", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async createCompanyFromResponse(body) {
    let data;
    data = body;
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    try {
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    } catch (e) {
      console.log(e);
    }

    let response = await postRequest(
      "companies/create-company-from-response",
      data
    );

    if (response) {
      console.log(
        "/companies/create-company-from-response",
        response.data.data
      );
      return response.data.data.data;
    } else {
      return [];
    }
  },
  async delete(data) {
    console.log("Call API and pass data Responses.delete", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", JSON.stringify(data));
    let response = await postRequest("responses/delete-one", data);
    if (response) {
      console.log("responses/delete-one", response);
      return response.data.data;
    } else {
      return response;
    }
  },

  async send(data, debug) {
    let logs = [];
    console.log("Call API and pass data for Responses.send", data);
    debug &&
      logs.push({
        message: "Call API and pass data for Responses.send",
        details: data,
        time: Date.now(),
      });
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    // ErrorLogging.LogFrontEnd("Call API, using this profile", profile);
    debug &&
      logs.push({
        message: "Call API, using this profile",
        details: profile,
        time: Date.now(),
      });

    // data.tenantId = profile.tenantId;
    // data.companyId = profile.companyId;
    // console.log("API body", JSON.stringify(data));
    let response = await postRequest("responses/send", data);
    if (response && response.data && response.data.status === 200) {
      debug &&
        logs.push({
          message: "responses/send",
          details: response,
          time: Date.now(),
        });
      console.log("Send solicitation got response", response);
      ErrorLogging.LogFrontEnd("Send Solicitation Logs", logs);
      return response.data.data;
    } else {
      debug &&
        logs.push({
          message: "responses/send failed",
          details: response,
          time: Date.now(),
        });
      console.log("Send solicitation failed", response);
      ErrorLogging.LogFrontEnd("Send Solicitation Logs", logs);
      return false;
    }
  },
  async list() {
    let data = {};
    console.log("Call API and pass data for Responses.list", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body - list projects", data);
    let response = await postRequest("responses/get-responses", data);
    if (response) {
      return response.data.data;
    } else {
      return response;
    }
  },
  async getByInviteId(data) {
    console.log("Call solicitation get-by-invite-id API and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", data);
    let response = await postRequest("solicitations/get-by-invite-id", data);
    if (response) {
      console.log(
        "Get solicitationId by inviteid got response",
        response.data.data
      );
      return response.data.data;
    } else {
      return response;
    }
  },
  async getById(data) {
    console.log("Call solicitation get-by-id API and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", data);
    let response = await postRequest("solicitations/get-by-id", data);
    if (response) {
      console.log("Get solicitation got response", response.data.data);
      return response.data.data;
    } else {
      return response;
    }
  },
  async getFullInvite(data) {
    console.log("Call API and pass data for GetFullInvite", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", JSON.stringify(data));
    let response = await postRequest(
      "solicitations/get-full-invite-by-id",
      data
    );
    if (response && response.data && response.data.data) {
      console.log("solicitations/get-full-invite-by-id got response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async getResponseByVendorId(data) {
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log(
      "Call API and pass data responses/get-by-vendor-and-solicitation-id",
      data
    );
    let response = await postRequest(
      "responses/get-by-vendor-and-solicitation-id",
      data
    );
    if (response) {
      console.log(
        "responses/get-by-vendor-and-solicitation-id response",
        response
      );
      return response.data.data;
    } else {
      return response;
    }
  },
  async getVendorProjects() {
    let data = {};
    console.log("Call API and pass data for Responses.getVendorProjects", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId
      ? profile.tenantCompanyId
      : profile.companyId;
    console.log("API body - list projects", data);
    let response = await postRequest("vendor/projects/get-by-vendor-id", data);
    if (response) {
      console.log(
        "vendor/projects/get-by-vendor-id response",
        response.data.data.returnData
      );
      return response.data.data.returnData;
    } else {
      return response;
    }
  },
  async getPendingBids() {
    let data = {};
    console.log("Call API and pass data for getPendingBids", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.email = profile.email;
    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId
      ? profile.tenantCompanyId
      : profile.companyId;
    data.domain = profile.tenantCompanyDomain
      ? profile.tenantCompanyDomain
      : null;
    console.log("API body - list projects", data);
    let response = await postRequest(
      "vendor/projects/get-pending-invites",
      data
    );
    if (response) {
      console.log(
        "vendor/projects/get-pending-invites",
        response.data.data.returnData
      );
      return response.data.data.returnData;
    } else {
      return response;
    }
  },
  async declineToView(data) {
    console.log("Call API and pass data for decline-to-view", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.email = profile.email;
    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId
      ? profile.tenantCompanyId
      : profile.companyId;
    data.domain = profile.tenantCompanyDomain
      ? profile.tenantCompanyDomain
      : null;
    console.log("API body - responses/decline-to-view", data);
    let response = await postRequest("responses/decline-to-view", data);
    if (response) {
      console.log("responses/decline-to-view", response.data);
      return response.data;
    } else {
      return response;
    }
  },
  async getPricingScenarios() {
    let data = {
      // "service":"credit_monitoring",
      // scenario: "Default",
      includeLineItems: true,
    };
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    // data.tenantId = profile.tenantId;
    // data.companyId = profile.companyId;
    // console.log("API body", JSON.stringify(data));
    let response = await postRequest("responses/get-all-scenarios", data);
    if (response && response.data && response.data.data) {
      console.log("responses/get-all-scenarios", response.data.data.scenarios);
      return response.data.data.scenarios;
    } else {
      return response;
    }
  },
};
